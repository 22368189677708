// Libraries
import Lottie from "react-lottie-player";
import { useMediaQuery } from "react-responsive";

// Components
import SocialBar from "../components/shared/SocialBar";

// Images and animations

import fire from "lotties/fire.json";
import fireMobile from "lotties/fireMobile.json";
import svg from "svg";

export default function Welcome() {
  // Defines max width for tablet and mobile in the landing page
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  return (
    <div className="w-full h-full pt-16 md:pt-12 grid cols grid-cols-[1fr_40px] grid-rows-[1fr_minmax(50vh,_auto)] items-end justify-start max-h-screen scroller">
      <div className="flex h-full row-span-1 col-span-full justify-center">
        <h1 className="text-[9.3vw] sm:text-[60px] lg:text-8xl self-center text-center sh:pt-0 sh:self-start font-extralight">
          Hi there! I'm{" "}
          <img
            className="inline h-[6vw] sm:h-[40px] lg:h-16 translate-y-[-1px]"
            src={svg.logo}
            alt="Carme's Logo"
          />
          <br />
          Product Designer
        </h1>
      </div>

      <div className="fixed left-0 bottom-[36px] z-10 w-screen xs:w-[85vw] lg:w-[830px] lg:max-w-[90vh]">
        <Lottie
          className="lottieHeight"
          loop={true}
          play={true}
          animationData={isMobile ? fireMobile : fire}
          style={{ maxWidth: "100%", alignSelf: "end" }}
        />
      </div>
      {!isMobile && <SocialBar />}
    </div>
  );
}
