import Heading3 from "components/text/Heading3";
import Paraph from "components/text/Paraph";
import { MutableRefObject } from "react";

interface Props {
  className: string;
  setRef?: MutableRefObject<null>;
  column: string;
  row?: string;
  link?: string;
  title?: string;
  text: string;
  text2?: string;
}

export default function InfoBox({
  className,
  setRef,
  column,
  row,
  link,
  title,
  text,
  text2,
}: Props) {
  return (
    <div
      ref={setRef}
      className={`${className ?? ""} flex flex-col`}
      style={{
        gridColumn: column,
        gridRow: row,
      }}
    >
      {link ? (
        <a rel="noreferrer" target="_blank" href={link}>
          <Heading3 className="pb-4 underline hover:bg-underline hover:no-underline xl:leading-tight">
            {title}
          </Heading3>
        </a>
      ) : (
        <Heading3 className="pb-4 xl:leading-tight">{title}</Heading3>
      )}
      <Paraph>{text}</Paraph>
      <Paraph>{text2}</Paraph>
    </div>
  );
}
