import ContentBlog from "components/blogComponents/ContentBlog";
import ContentsMenu from "components/blogComponents/ContentsMenu";
import Image from "components/blogComponents/Image";
import TwoCols from "components/blogComponents/TwoCols";
import ScrollToTopButton from "components/shared/ScrollToTopButton";
import Heading2 from "components/text/Heading2";
import Paraph from "components/text/Paraph";
import { useAuthBlog, useBlog, useListBlogs } from "queries/queries";
import { Link, useParams } from "react-router-dom";
import { BlogRespType } from "types/reponseTypes";
import AuthBlogLayer from "./AuthBlogLayer";
import { useState, useEffect } from "react";

type bodyComponents = "image" | "content" | "twoCols";
interface Props {
  item: BlogRespType;
}

function BlogBody({ item }: Props) {
  const comp: { [key in bodyComponents]: JSX.Element } = {
    image: <Image item={item} />,
    content: <ContentBlog item={item} />,
    twoCols: <TwoCols item={item} />,
  };
  const { componentType: type } = item;

  if (
    type === "title" ||
    type === "preview" ||
    type === "description" ||
    type === "category"
  ) {
    return null;
  }

  return comp[type];
}

export default function BlogTemplate() {
  const { blog } = useParams();
  const { data, isLoading, isError } = useBlog(blog ?? "");
  const { data: blogsData } = useListBlogs();
  const [isPublic, setIsPublic] = useState(false);
  const isSessionAuth = window.sessionStorage.getItem("blogAuth") === "true";

  const {
    data: isAPIAuth,
    mutate,
    isLoading: isAuthLoading,
    isError: isAuthError,
  } = useAuthBlog();

  useEffect(() => {
    if (blogsData) {
      const b = blogsData.find((b) => b.id === blog);
      setIsPublic(!!b?.public);
    }
  }, [blog, blogsData]);

  if (isLoading) {
    return (
      <div className="flex w-screen h-screen items-center justify-center">
        <p className="text-2xl sm:text-5xl pb-60 animate-pulse">Loading...</p>
      </div>
    );
  }

  if (isError) {
    return <Paraph>Error</Paraph>;
  }

  if (!isPublic && !(isSessionAuth || isAPIAuth)) {
    console.log({
      session: !isSessionAuth,
      api: !isAPIAuth,
      sum: !isPublic && !(isSessionAuth || isAPIAuth),
    });

    return (
      <AuthBlogLayer
        submit={mutate}
        isLoading={isAuthLoading}
        isError={isAuthError}
      />
    );
  }
  return (
    <>
      <div className="col-span-full flex justify-center pb-60 max-w-full">
        <div className="flex flex-col md:grid md:grid-cols-6 justify-center max-w-full md:max-w-7xl gap-16 md:gap-28 p-4 pt-8">
          <div className="col-span-full flex flex-col justify-items-center gap-6 w-full">
            <Link
              className="md:text-2xl font-normal underline hover:bg-underline hover:no-underline w-min whitespace-nowrap"
              to="/work"
            >
              {"< BACK"}
            </Link>
            <div>
              <Paraph className="w-full uppercase font-light">
                {data.category}
              </Paraph>
              <Heading2 className="w-full leading-snug">{data.title}</Heading2>
            </div>
            <img className="col-span-full w-full" src={data.image} alt="" />
          </div>
          <ContentsMenu
            items={data.body
              .map((i) => i.section)
              .filter((i): i is string => !!i)}
          />

          {data.body.map((b, i) => (
            <BlogBody key={i} item={b} />
          ))}
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
}
