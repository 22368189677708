import React from "react";

// Dependencies
import { Link } from "react-router-dom";

// Images
import svg from "../../../svg";

interface Props {
  link: string;
}
function Logo({ link }: Props) {
  return (
    <Link to={link}>
      <img
        className="h-4 sm:mt-0 lg:h-[1.7rem]"
        src={svg.logo}
        alt="Carme's logo"
      ></img>
    </Link>
  );
}

export default Logo;
