// Components
import HeroAnimation from "components/shared/HeroAnimation";
import ProProjects from "components/work/ProProjects";

// Animations
import work from "lotties/work.json";
import workMobile from "lotties/workMobile.json";

// Images
import png from "png";

// Style
import "styles/appearOnScroll.css";

// Hooks
import { useAppear } from "hooks/IntObs";
import { useMediaQuery } from "react-responsive";
import Paraph from "components/text/Paraph";
import ButtonLink from "components/shared/ButtonLink";

export default function Work() {
  const isTablet = useMediaQuery({ query: "(max-width: 800px)" });
  const [ref1, appear1] = useAppear();

  return (
    <div className="grid grid-cols-layout-sm md:grid-cols-layout col-span-full sm:pt-4">
      <HeroAnimation animationData={isTablet ? workMobile : work} />
      <ProProjects setRef={ref1} className={appear1} isTablet={isTablet} />
      <div className="pb-36 sm:pb-60 grid grid-cols-layout-sm md:grid-cols-layout col-span-full">
        <Paraph className="text-[4rem] sm:text-[2.1rem] col-start-2 col-span-10 md:col-start-4 md:col-span-5 pb-20">
          Checkout my{" "}
          <a
            className="underline hover:bg-underline hover:no-underline"
            rel="noreferrer"
            target="_blank"
            href="https://dribbble.com/Carmevq"
          >
            dribbble profile
          </a>{" "}
          to see more personal projects, concepts and ideas.
        </Paraph>
        <img
          src={png.concepts}
          alt="images of personal projects"
          className="w-full col-[2/-2]"
        />
      </div>
      <ButtonLink link="/about">About Me</ButtonLink>
    </div>
  );
}
