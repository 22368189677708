import { MutableRefObject } from "react";
import InfoBox from "components/shared/InfoBox";
import { useBlog, useListBlogs } from "queries/queries";
import { Link } from "react-router-dom";
import Paraph from "components/text/Paraph";
import { ReactComponent as Arrow } from "svg/arrow-right.svg";

interface Props {
  className: string;
  setRef: MutableRefObject<null>;
  isTablet: boolean;
}

function ProjectCard({ blog }: { blog: string }) {
  const { data, isLoading, isError } = useBlog(blog ?? "");

  if (isLoading) {
    return <Paraph>Loading</Paraph>;
  }

  if (isError) {
    return null;
  }
  return (
    <Link className="flex flex-col" to={blog}>
      <div className="relative px-0 xs:px-20 md:!px-0">
        <div className="p-24 flex items-center justify-center gap-6 absolute w-full h-full bg-yellow opacity-0 md:hover:opacity-100 border-black border-[1.5px] md:transition">
          <span className="text-5xl">View </span>
          <Arrow className="w-auto h-full" />
        </div>
        <img className="" src={data.preview} alt="" />
      </div>
      <Paraph className="font-light text-base sm:!text-2xl uppercase pb-1 pt-2 md:pb-3 md:pt-4">
        {data.category}
      </Paraph>
      <Paraph className="leading-tight md:leading-snug">{data.title}</Paraph>
      <Paraph className="text-base sm:!text-2xl pt-2 md:pt-4 font-light">
        {data.description}
      </Paraph>
    </Link>
  );
}

function ProProjects({ className, setRef, isTablet }: Props) {
  const { data: blogs } = useListBlogs();

  return (
    <div
      className={`pb-36 sm:pb-80 grid grid-cols-layout-sm md:grid-cols-layout col-span-full pt-[15vh] ${
        className ?? ""
      }`}
    >
      <InfoBox
        setRef={setRef}
        title="Check out some of my projects"
        text="See some examples of projects I’ve worked on over the last years. Due to client confidentiality I can’t share more details in here, please get in touch if you want to know more."
        column={isTablet ? "2 / span 10" : "4 / span 5"}
        className="md:py-20"
      />
      <div
        id="projects"
        className="grid grid-cols-1 auto-rows-auto col-[2/-2] md:grid-cols-3 gap-8 justify-center py-8"
      >
        <>
          {!blogs ? (
            <Paraph>Loading...</Paraph>
          ) : (
            blogs.map((blog, i) => {
              return <ProjectCard key={`${blog}${i}`} blog={blog.id} />;
            })
          )}
        </>
      </div>
    </div>
  );
}

export default ProProjects;
