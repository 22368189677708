import "styles/socialBar.css";

// Dependencies
import { useMediaQuery } from "react-responsive";

// Icons Libraries
import { FaDribbble, FaBehance, FaLinkedinIn } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

interface Props {
  className?: string;
}

function SocialBar({ className }: Props) {
  // Detects height to change the email to an icon
  const isShort = useMediaQuery({ query: "(max-height: 650px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div className={`social-bar ${className ?? ""}`}>
      {isTablet || isShort ? (
        <a color="black" href="mailto:hello@carmevidal.uk">
          <FiMail />
        </a>
      ) : (
        <a className="vertical" color="black" href="mailto:hello@carmevidal.uk">
          hello@carmevidal.uk
        </a>
      )}
      <a
        rel="noreferrer"
        target="_blank"
        color="black"
        href="https://dribbble.com/Carmevq"
      >
        <FaDribbble />
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        color="black"
        href="https://www.behance.net/carmevidal1beb/projects"
      >
        <FaBehance />
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        color="black"
        href="https://www.linkedin.com/in/carme-vidal/"
      >
        <FaLinkedinIn />
      </a>
    </div>
  );
}

export default SocialBar;
