import { useState, useLayoutEffect, useRef, MutableRefObject } from "react";

function useIntObs(): [MutableRefObject<null>, boolean] {
  const itemRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  function callbackFunction(entries: { isIntersecting: boolean }[]) {
    const entry = entries[0].isIntersecting;
    entry && setIsVisible(true);
  }

  useLayoutEffect(() => {
    const localRef = itemRef;
    const options = { root: null, rootMargin: "0px", threshold: 0.5 };
    const observer = new IntersectionObserver(callbackFunction, options);
    if (localRef.current) observer.observe(localRef.current);

    return () => {
      if (localRef.current) observer.unobserve(localRef.current);
    };
  }, []);
  return [itemRef, isVisible];
}

function useAppear(): [MutableRefObject<null>, string] {
  const [itemRef, isVisible] = useIntObs();
  const style = isVisible ? "aos-entered" : "aos";
  return [itemRef, style];
}

export { useIntObs, useAppear };
