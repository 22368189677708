// Styles
import Heading3 from "components/text/Heading3";
import Paraph from "components/text/Paraph";

interface Props {
  box1Year: string;
  box2Year?: string;
  box1Type: string;
  box2Type?: string;
  box1Value: string;
  box2Value?: string;
  box1Title: string;
  box2Title: string;
  box1Desc: string;
  box2Desc?: string;
}

function TwoBoxes({
  box1Year,
  box2Year,
  box1Type,
  box2Type,
  box1Value,
  box2Value,
  box1Title,
  box2Title,
  box1Desc,
  box2Desc,
}: Props) {
  return (
    <div className="grid col-span-full grid-cols-[minmax(50px,_1fr)_repeat(2,_minmax(0,_690px)),_minmax(50px,_1fr)] border-black border-y-[1.5px]">
      <section className="p-5 col-start-1 col-span-4 md:col-start-2 md:col-span-1 md:p-[6rem_16%_6rem_0]">
        <div className="flex justify-between">
          <div>
            <Paraph>{box1Year}</Paraph>
            <Paraph>{box1Type}</Paraph>
          </div>
          <Paraph>{box1Value}</Paraph>
        </div>
        <Heading3>{box1Title}</Heading3>
        {box1Desc && <Paraph>{box1Desc}</Paraph>}
      </section>
      {box2Title && (
        <section className="p-5 col-start-1 col-span-4 md:col-start-3 md:col-span-1 md:p-[6rem_0_6rem_16%] border-t-[1.5px] border-black md:border-t-0 md:border-l-[1.5px]">
          <div className="flex justify-between">
            <div>
              <Paraph>{box2Year}</Paraph>
              <Paraph>{box2Type}</Paraph>
            </div>
            {box2Value && <Paraph>{box2Value}</Paraph>}
          </div>
          <Heading3>{box2Title}</Heading3>
          {box2Desc && <Paraph>{box2Desc}</Paraph>}
        </section>
      )}
    </div>
  );
}

export default TwoBoxes;
