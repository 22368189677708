import { Link } from "react-router-dom";

interface Props {
  link: string;
  children: string;
}

export default function ButtonLink({ link, children }: Props) {
  return (
    <div className="col-span-full border-t-black border-[1.5px]">
      <Link
        to={link}
        className={`flex w-full pb-16 pt-8 md:pb-24 justify-center md:pt-12 md:hover:bg-yellow hover:underline text-xl font-normal lg:text-2xl xl:text-3xl`}
      >
        {children}
      </Link>
    </div>
  );
}
