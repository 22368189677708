import Heading3 from "components/text/Heading3";
import Paraph from "components/text/Paraph";
import { useState } from "react";
import { RolType } from "types/types";

interface Props {
  className: string;
  isTablet: boolean;
  content: Array<RolType>;
}

function PullDownTab({ className, isTablet, content }: Props) {
  const [clicked, setClicked] = useState<number | false>(false);

  function handleClick(index: number) {
    index !== clicked ? setClicked(index) : setClicked(false);
  }

  return (
    <div
      className={`border-b border-black grid grid-cols-layout-sm md:grid-cols-layout col-span-full ${className}`}
    >
      {content.map((rol, index) => (
        <div
          key={index}
          className="grid grid-cols-layout-sm md:grid-cols-layout col-span-full"
          onClick={() => {
            handleClick(index);
          }}
        >
          <div
            className={`${
              clicked === index ? "" : "md:hover:bg-yellow"
            } cursor-pointer grid grid-rows-[auto_1fr_auto] grid-cols-layout-sm md:grid-cols-layout col-span-full whitespace-nowrap md:text-[2rem] md:font-light items-center py-1 border-t border-black bg-whitecream`}
          >
            <p className="col-[2_/_span_11] md:col-[2_/_span_3] leading-normal">
              {rol.date}
            </p>
            <Heading3 className="pr-[10px] col-[2_/_span_11] md:col-[6_/_span_5] leading-tight">
              {rol.title}
            </Heading3>
            <p className="md:pl-16 col-[2_/_span_11] md:col-[11_/_span_3] whitespace-nowrap leading-normal md:text-[2rem] md:font-light">
              {rol.company}
            </p>
            {isTablet && (
              <span className="text-2xl md:text-8xl md:font-light col-[-3] row-[1_/_-1]">
                {clicked === index ? "-" : "+"}
              </span>
            )}
          </div>
          <div className="grid grid-cols-layout-sm md:grid-cols-layout col-span-full">
            {clicked === index && (
              <Paraph className="py-4 md:py-16 col-[2_/_-2]">
                {rol.description}
              </Paraph>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PullDownTab;
