import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}
export default function Paraph({ children, className = "" }: Props) {
  return (
    <p
      className={`text-xl md:text-2xl lg:text-2xl xl:text-3xl leading-[normal] lg:leading-[normal] xl:leading-[normal] ${className}`}
    >
      {children}
    </p>
  );
}
