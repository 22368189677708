// Dependencies
import { CSSTransition } from "react-transition-group";
import Lottie from "react-lottie-player";

// Styles
import "styles/hero.css";

interface Props {
  animationData: object | undefined;
}
function HeroAnimation({ animationData }: Props) {
  const timeout = {
    appear: 2000,
  };
  return (
    <CSSTransition
      appear={true}
      exit={false}
      in={true}
      timeout={timeout}
      classNames="lottie"
    >
      <Lottie
        className="hero"
        loop={false}
        play={true}
        animationData={animationData}
      />
    </CSSTransition>
  );
}

export default HeroAnimation;
