// Dependencies
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from "pages/Welcome";
import Layout from "components/layouts/Layout";
import Work from "pages/Work";
import AboutMe from "pages/AboutMe";
import BlogTemplate from "pages/BlogTemplate";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/work" element={<Work />} />
            <Route path="/work/:blog" element={<BlogTemplate />} />
            <Route path="*" element={<h1> 404 </h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
