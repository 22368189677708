import { useMutation, useQuery } from "@tanstack/react-query";
import { BlogClient, Client } from "api/api";
import {
  ATRespType,
  BlogRespType,
  BlogsRespType,
  ComponentType,
  RecordsType,
} from "types/reponseTypes";

function isType(predata: RecordsType<BlogRespType>[], type: ComponentType) {
  return predata.find((b) => b.fields.componentType === type)?.fields;
}

async function listBlogs() {
  const response = await Client.get<ATRespType<BlogsRespType>>(
    `appXi6zkV9Mrl2WnX/list?maxRecords=6`
  );

  const cleanData: BlogsRespType[] = response.data.records.map((r) => {
    return {
      id: r.fields.id,
      public: r.fields.public,
      password: r.fields.password,
    };
  });
  return cleanData;
}

async function getBlog(id: string) {
  const response = await Client.get<ATRespType<BlogRespType>>(
    `appXi6zkV9Mrl2WnX/${id}`
  );

  const predata = response.data.records.sort(
    (a, b) => a.fields.index - b.fields.index
  );

  const cleanData = {
    category: isType(predata, "category")?.richText,
    title: isType(predata, "title")?.richText,
    preview: isType(predata, "preview")?.image?.[0].url,
    description: isType(predata, "description")?.richText,
    image: isType(predata, "image")?.image?.[0].url,
    body: predata.slice(5).map((b) => b.fields),
  };
  return cleanData;
}

async function postBlogAuth(pass: string) {
  const response = await BlogClient.post("/blog-auth", { pass: pass });
  return response.data;
}

// USeQuery hooks
export function useListBlogs() {
  return useQuery(["jobs"], listBlogs);
}

export function useBlog(id: string) {
  return useQuery([`table${id}`], () => getBlog(id), {
    enabled: !!id && id !== "",
    refetchOnMount: false,
  });
}

export function useAuthBlog() {
  return useMutation((pass: string) => postBlogAuth(pass), {
    onSuccess: () => {
      window.sessionStorage.setItem("blogAuth", "true");
    },
    onError: () => {
      return window.sessionStorage.removeItem("blogAuth");
    },
  });
}
