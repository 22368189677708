import { useLocation } from "react-router-dom";
import InfoFooter from "./Components/InfoFooter";
import ScrollDisplay from "./Components/ScrollDisplay";

// Components

function Footer() {
  let path = useLocation().pathname;

  return (
    <footer className="fixed bottom-0 w-screen border-t-[1px] border-t-[black] bg-whitecream">
      {path === "/" ? <ScrollDisplay /> : <InfoFooter />}
    </footer>
  );
}

export default Footer;
