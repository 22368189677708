import { BlogRespType } from "types/reponseTypes";
import BlogEndLine from "./BlogEndLine";
import RichText from "./RichText/RichText";

interface Props {
  item: BlogRespType;
}

export default function ContentBlog({ item }: Props) {
  const { section, richText, subtitle, end } = item;

  return (
    <div id={section} className="col-start-1 col-end-6 flex flex-col gap-5">
      <h4 className="uppercase text-lg md:text-2xl font-light">{section}</h4>
      {subtitle && (
        <RichText className="text-3xl font-normal" text={subtitle} />
      )}
      {richText && <RichText className="font-light" text={richText} small />}
      {end && <BlogEndLine />}
    </div>
  );
}
