import { forwardRef, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const Heading2 = forwardRef<null, Props>(
  ({ children, className = "" }: Props, ref) => (
    <h2
      ref={ref}
      className={`text-4xl md:text-5xl lg:text-7xl md:leading-tight lg:leading-tight ${className}`}
    >
      {children}
    </h2>
  )
);

export default Heading2;
