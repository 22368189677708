import { forwardRef, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const Heading3 = forwardRef<null, Props>(
  ({ children, className = "" }: Props, ref) => (
    <h3
      ref={ref}
      className={`font-normal text-2xl sm:text-3xl md:text-4xl xl:text-5xl ${className}`}
    >
      {children}
    </h3>
  )
);

export default Heading3;
