// Dependencies
import Lottie from "react-lottie-player";

import { useMediaQuery } from "react-responsive";

// Components
import HeroAnimation from "components/shared/HeroAnimation";
import Learned from "components/me/Learned";
import PullDownTab from "components/me/PullDownTab";
import InfoBox from "components/shared/InfoBox";
import TwoBoxes from "components/shared/TwoBoxes";

// Images and animations
import me from "lotties/me.json";
import meMobile from "lotties/meMobile.json";
import experiencies from "lotties/experiencies.json";
import bin from "lotties/bin.json";
import svg from "svg";

// Databases
import experience from "databases/experience";

// Custom Hooks
import { useAppear } from "../hooks";
import Heading2 from "components/text/Heading2";
import ButtonLink from "components/shared/ButtonLink";

export default function AboutMe() {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 800px)" });

  const [ref1, appear1] = useAppear(),
    [ref2, appear2] = useAppear(),
    [ref3, appear3] = useAppear(),
    [ref4, appear4] = useAppear(),
    [ref5, appear5] = useAppear(),
    [ref6, appear6] = useAppear(),
    [ref7, appear7] = useAppear(),
    [ref8, appear8] = useAppear(),
    [ref9, appear9] = useAppear();

  return (
    <div className="grid grid-cols-layout-sm md:grid-cols-layout col-span-full sm:pt-4">
      <HeroAnimation animationData={isTablet ? meMobile : me} />
      <InfoBox
        setRef={ref1}
        title="Bristol based product designer"
        text={
          "End-to-end design of digital experiences and services, from research to delivery."
        }
        column={isMobile ? "2 / span 9" : "4 / span 4"}
        row="2"
        className={"pt-[15vh] mt-0 " + appear1}
      />
      <div
        ref={ref2}
        className={`col-[6/_-2] sm:col-[9/_-3] pt-12 pb-8 sm:py-0 sm:row-start-3 ${appear2}`}
      >
        <Lottie
          className="lottieHeight"
          loop={true}
          play={true}
          animationData={experiencies}
        />
      </div>
      <InfoBox
        setRef={ref3}
        text="As a designer I understand that my role is to ensure that the solutions presented respond to real problems and needs, so we can build great products, services and experiences that matter to people."
        column={isMobile ? "2 / span 9" : "4 / span 4"}
        row="4"
        className={appear3}
      />
      <div
        ref={ref4}
        className={`col-[6/_-2] sm:col-[9/_-3] pt-8 pb-24 sm:py-0 sm:pb-52 sm:row-start-5 h-full ${appear4}`}
      >
        <Lottie
          className="lottieHeight"
          loop={true}
          play={true}
          animationData={bin}
        />
      </div>
      <Heading2
        ref={ref5}
        className={`col-[2_/_-1] text-start pb-4 sm:pb-16 ${appear5}`}
      >
        Experience
      </Heading2>
      <PullDownTab
        content={experience}
        className={appear5}
        isTablet={isTablet}
      />
      <img
        className={`col-start-7 col-span-6 md:col-start-11 md:col-span-3 w-full pt-24 pb-10 sm:pb-16 ${appear5}`}
        src={svg.three_years}
        alt="three years experience"
      />
      <article className="grid col-start-2 col-span-12 grid-cols-internal pb-24 sm:pb-52">
        <Learned setRef={ref6} className={appear6} />
      </article>
      <article
        className={`pb-16 sm:pb-24 grid grid-cols-layout-sm md:grid-cols-layout col-span-full ${appear7}`}
      >
        <Heading2 ref={ref7} className="pb-4 sm:pb-16 col-[2_/_-2]">
          Education
        </Heading2>
        <TwoBoxes
          box1Year="2012 - 16"
          box1Type="Bachelors degree"
          box1Value="240 cred."
          box1Title="Industrial Design Engineering and Product Development"
          box1Desc="Escola universitaria de Deseño Industrial (EUDI), Universidade da Coruña (UDC), Ferrol"
          box2Year="2016 - 18"
          box2Type="Masters degree"
          box2Value="120 cred."
          box2Title="Interaction Design (IxD)"
          box2Desc="IADE Creative University, Lisboa"
        />
      </article>
      <article className="grid grid-cols-layout-sm md:grid-cols-layout col-span-full pb-24 sm:pb-52">
        <img
          ref={ref8}
          className={`col-start-2 col-span-3 w-72 pr-2 ${appear8}`}
          src={svg.course}
          alt=""
        />
        <InfoBox
          title="Strategic branding, identity & experience"
          text="UAL Central Saint Martins, London"
          text2="Dec 2019"
          column={isTablet ? "4 / -2" : "4 / span 5"}
          className={`pt-6 pb-14 sm:pb-24 ${appear8}`}
        />
        <img
          ref={ref9}
          className={`col-start-2 col-span-3 md:col-start-7 md:col-span-3 w-72 ${appear9}`}
          src={svg.publication}
          alt=""
        />
        <InfoBox
          title="Design of a Situation-Awareness Solution to Support Infrastructure
          Inspections"
          text="WorldCIST’19: New Knowledge in Information Systems and Technologies"
          text2="Mar 2019"
          column={isTablet ? "4 / -2" : "9 / span 5"}
          className={`pt-6  ${appear9}`}
          link="https://link.springer.com/chapter/10.1007/978-3-030-16181-1_37#citeas"
        />
      </article>
      <ButtonLink link="/work">Check out some of my projects</ButtonLink>
    </div>
  );
}
