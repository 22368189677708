import svg from "../../../svg";

const footerText = [
  "USER EXPERIENCE",
  "PRODUCT STRATEGY",
  "VISUAL DESIGN",
  "BRANDING",
  "USER RESEARCH",
  svg,
];

export default function ScrollDisplay() {
  const filledArray = [...footerText, ...footerText, ...footerText];

  const display = filledArray.map((text, index) =>
    typeof text === "string" ? (
      <p key={index} className="static text-[28px] -z-10">
        {text}
      </p>
    ) : (
      <img key={index} src={text.asterisk} alt="*" />
    )
  );

  return (
    <div
      className={`animate-footerAnimation w-footerAnimation relative grid grid-flow-col items-center sm:mt-[1px] sm:mb-[3px] -z-10`}
    >
      {display}
    </div>
  );
}
