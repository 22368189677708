import Paraph from "components/text/Paraph";
import { ReactNode } from "react";
import ReactMarkdown from "react-markdown";

interface Props {
  children: ReactNode;
  small?: boolean;
}

function H3({ children }: Props) {
  return <Paraph className="pb-2 inline-block">{children}</Paraph>;
}

function BlogP({ children, small }: Props) {
  return (
    <Paraph
      className={`mb-5 md:mb-10 last:mb-0 ${
        small ? "text-base md:!text-2xl font-light md:!leading-normal" : ""
      }`}
    >
      {children}
    </Paraph>
  );
}

function BlogA({ href, children }: { children: ReactNode; href?: string }) {
  return (
    <a
      href={href}
      className="text-inherit font-inherit underline hover:bg-underline hover:no-underline"
    >
      {children}
    </a>
  );
}

function BlogLi({ children, small }: Props) {
  return (
    <li
      className={`pl-4 py-2 font-light ${
        small ? "text-base md:!text-2xl font-light md:!leading-normal" : ""
      }`}
    >
      {children}
    </li>
  );
}
function BlogUl({ children, small }: Props) {
  return (
    <ul
      className={`ml-[20px] list-disc pb-10 last:pb-0 ${
        small ? "text-base md:!text-2xl font-light md:!leading-normal" : ""
      }`}
    >
      {children}
    </ul>
  );
}

export default function RichText({
  className,
  text,
  small,
}: {
  className?: string;
  text: string;
  small?: boolean;
}) {
  if (!text || text === "" || text === "\n") {
    return null;
  }
  return (
    <ReactMarkdown
      components={{
        h3: H3,
        a: BlogA,
        p: (e) => <BlogP small={small}>{e.children}</BlogP>,
        ul: (e) => <BlogUl small={small}>{e.children}</BlogUl>,
        li: (e) => <BlogLi small={small}>{e.children}</BlogLi>,
      }}
      className={`text-2xl font-normal ${className ?? ""}`}
      children={text}
    />
  );
}
