import { useState } from "react";

// Components
import Button from "../../shared/Button";
import SocialBar from "../../shared/SocialBar";
import Logo from "./Logo";

// Images
import svg from "../../../svg";

export default function BurguerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }
  return (
    <>
      {!isOpen && (
        <div className="block sm:hidden h-6" onClick={handleClick}>
          <img src={svg.burguer_menu} alt="open menu" />
        </div>
      )}
      {isOpen && (
        <div
          className="bg-whitecream fixed inset-0 px-4 pt-4 flex sm:hidden flex-col"
          onClick={handleClick}
        >
          <header className="flex justify-between w-full items-center">
            <Logo link="/" />
            <img className="h-6" src={svg.close_x_icon} alt="close menu" />
          </header>
          <div className="w-full py-24 grid grid-cols-1 grid-rows-[repeat(auto-fit, 8rem)] gap-2 content-start w-full">
            <Button link="/about" child="ABOUT ME" />
            <Button link="/work" child="ABOUT MY WORK" />
          </div>

          <SocialBar className="row-start-4 row-span-2" />
        </div>
      )}
    </>
  );
}
