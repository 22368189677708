import { useMediaQuery } from "react-responsive";
import { BlogRespType } from "types/reponseTypes";
import BlogEndLine from "./BlogEndLine";

interface Props {
  item: BlogRespType;
}

export default function Image({ item }: Props) {
  const isTablet = useMediaQuery({ query: "(max-width: 800px)" });
  const { image, cols, end } = item;
  return (
    <div className="col-span-full grid grid-cols-6 justify-center">
      <img
        src={image?.[0].url}
        style={{ gridColumn: isTablet ? "1 / -1" : cols }}
        alt={item.title}
      />
      {end && <BlogEndLine />}
    </div>
  );
}
