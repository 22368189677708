import project1 from 'png/project1.png';
import project2 from 'png/project2.png';
import project3 from 'png/project3.png';
import project4 from 'png/project4.png';
import project5 from 'png/project5.png';
import project6 from 'png/project6.png';
import concepts from 'png/concepts.png';

const png = {
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
  concepts
}

export default png;
