import axios from "axios";

export const Client = axios.create({
  baseURL: process.env.REACT_APP_AIRTABLE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
  },
});

export const BlogClient = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_SECRET_API,
});
