import approach from "svg/approach.svg";
import asking from "svg/asking.svg";
import asterisk from "svg/asterisk.svg";
import behance_icon_color from "svg/behance_icon_color.svg";
import bin from "svg/bin.svg";
import burguer_menu from "svg/burger_menu.svg";
import close_x_icon from "svg/close_x_icon.svg";
import course from "svg/course_icon.svg";
import dribbble_icon_color from "svg/dribbble_icon_color.svg";
import hover_live from "svg/hover_live.svg";
import hover_behance from "svg/hover_behance.svg";
import linkedin_icon_color from "svg/linkedin_icon_color.svg";
import logo from "svg/logo.svg";
import process_mobile from "svg/process_mobile.svg";
import publication from "svg/publication.svg";
import three_years from "svg/fouryears.svg";
import window_icons from "svg/window_icons.svg";
import { ReactComponent as work_hero } from "svg/work_hero.svg";

const svg = {
  approach,
  asking,
  asterisk,
  behance_icon_color,
  bin,
  burguer_menu,
  close_x_icon,
  course,
  dribbble_icon_color,
  hover_live,
  hover_behance,
  linkedin_icon_color,
  logo,
  process_mobile,
  publication,
  three_years,
  window_icons,
  work_hero,
};

export default svg;
