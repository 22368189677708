const experience = [
  {
    title: "Product Designer",
    company: "RM",
    date: "Aug 21 - Now",
    description:
      "Uncovering, developing and validating new ideas and opportunities for our customers and the business by following a user-centred and problem-first approach. Generating and iterating new designs and concepts to bring new ideas to life. Engaging in planning, facilitating and analysing insights from UX research activities such as usability testing, interviews, workshops or focus groups.",
  },
  {
    title: "Product Designer",
    company: "IVC Evidensia",
    date: "Oct 20 - Aug 21",
    description:
      "End-to-end design of digital experiences and services, from research to delivery. Engaging in planning, facilitating and analysing insights from UX research activities such as usability testing, stakeholder interview or focus groups; to set direction for brand and/or product strategies; identifying and prioritising design requirements. Owning and managing designs throughout their lifecycle, pairing with developers to transform wireframes and prototypes into functioning products.",
  },
  {
    title: "Visual Designer",
    company: "IVC Evidensia",
    date: "Aug 18 - Nov 20",
    description:
      "Focusing on improving the user experience through considering the effects of visual elements. Strategically implementing illustrations, photography, typography, space, layouts, color, etc., to translate client business requirements, user needs and technical requirements into designs that are easy to use, innovative and visually engaging.",
  },
  {
    title: "UX/UI Intern",
    company: "Pro-Drone",
    date: "Jul 17 - Dec 20",
    description:
      "Focussing on improving the UI of their wind-turbine inspections solution (BladeInsight), I developed the following activities: Scenarios, Task flows, Service Diagramas, Personas, User journeys, Storyboards, Usability Tests, Test Reports, Heuristic Evaluation, Wireframes, Information Architecture, BPMN Diagrams, Prototypes, Style Guides.",
  },
];

export default experience;
