import { BlogRespType } from "types/reponseTypes";
import BlogEndLine from "./BlogEndLine";
import RichText from "./RichText/RichText";

interface Props {
  item: BlogRespType;
}

export default function TwoCols({ item }: Props) {
  const { section, richText, subtitle, end } = item;

  return (
    <div
      id={section}
      className="col-span-full flex flex-col md:grid md:grid-cols-2"
    >
      <div className="flex flex-col md:w-2/3 gap-5 pb-6 md:pb-0">
        <h4 className="uppercase md:text-2xl font-light">{section}</h4>
        {subtitle && <RichText className="font-normal" text={subtitle} />}
      </div>
      <div>
        {richText && (
          <RichText className="pt-2 md:pt-4" text={richText} small />
        )}
      </div>
      {end && <BlogEndLine />}
    </div>
  );
}
