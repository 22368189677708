// Components
import Button from "../../shared/Button";

export default function Menu() {
  return (
    <div className="hidden sm:grid col-start-2 grid-cols-2 gap-8 items-center justify-items-right whitespace-nowrap">
      <Button link="/about" child="ABOUT ME" />
      <Button link="/work" child="MY WORK" />
    </div>
  );
}
