import { ReactComponent as ScrollToTop } from "svg/scroll-to-top.svg";
import { HashLink } from "react-router-hash-link";

export default function ScrollToTopButton() {
  return (
    <div className="fixed -left-2 bottom-12 md:left-1/2 md:-translate-x-1/2 md:bottom-36 max-w-7xl w-full flex justify-end">
      <HashLink to="#top">
        <ScrollToTop className="w-14 h-14" />
      </HashLink>
    </div>
  );
}
