// Dependencies
import BurguerMenu from "./Components/BurguerMenu";
import Logo from "./Components/Logo";
import Menu from "./Components/Menu";

// Components

export default function Header() {
  return (
    <header className="bg-whitecream sticky px-4 pt-4 sm:px-7 md:px-8 flex justify-between gap-8 items-center top-0 inset-x-0 z-20 w-full md:w-auto">
      <Logo link="/" />
      <Menu />
      <BurguerMenu />
    </header>
  );
}
