import Paraph from "components/text/Paraph";
import React from "react";

// Images
import svg from "../../../svg";

const footerImg = [
  { src: svg.dribbble_icon_color, href: "https://dribbble.com/Carmevq" },
  {
    src: svg.behance_icon_color,
    href: "https://www.behance.net/carmevidal1beb/projects",
  },
  {
    src: svg.linkedin_icon_color,
    href: "https://www.linkedin.com/in/carme-vidal/",
  },
];

function InfoFooter() {
  return (
    <div className="flex md:grid grid-cols-[auto_1fr_auto] text-center items-center py-[2.5px] px-16">
      <Paraph className="hidden md:block text-[2.1rem]">Say hi! :)</Paraph>
      <Paraph className="text-[4rem] sm:text-[2.1rem] text-center w-full">
        hello@carmevidal.uk
      </Paraph>
      <div className="hidden md:grid col-start-[-2] grid-flow-col gap-8 items-center">
        {footerImg.map((icon, i) => (
          <a
            key={`${i}${icon.href}`}
            rel="noreferrer"
            target="_blank"
            href={icon.href}
          >
            <img
              className="max-h-[24px] sm:max-h-[3.4rem]"
              src={icon.src}
              alt=""
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default InfoFooter;
