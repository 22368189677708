// Dependencies
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  onClick?: () => void;
  link: string;
  child: ReactNode;
}

export default function Button({ onClick, link, child }: Props) {
  return (
    <Link
      onClick={onClick}
      to={link}
      className={`hover:underline text-xl font-normal lg:text-2xl xl:text-3xl`}
    >
      {child}
    </Link>
  );
}
