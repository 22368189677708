import Heading3 from "components/text/Heading3";
import Paraph from "components/text/Paraph";
import { MouseEvent, useState } from "react";
import { ReactComponent as Cofre } from "svg/cofre.svg";
import PulseLoader from "react-spinners/PulseLoader";

interface Props {
  submit: (pass: string) => void;
  isLoading: boolean;
  isError: boolean;
}

export default function AuthBlogLayer({ submit, isLoading, isError }: Props) {
  const [pass, setPass] = useState<string>();

  function handleSubmit(e: MouseEvent<HTMLFormElement>) {
    e.preventDefault();
    if (pass) {
      return submit(pass);
    }
  }

  return (
    <div className="flex justify-center w-full h-[90%] bg-whitecream pt-14 md:pt-0 md:pb-11">
      <div className="flex flex-col items-center justify-center gap-y-5 max-w-xs md:max-w-2xl">
        <div className="md:pb-4">
          <Cofre />
        </div>
        <form onClick={(e) => handleSubmit(e)}>
          <label className="flex flex-col text-center gap-3 md:gap-5 items-center justify-center w-full">
            <Heading3 className="md:h-16">Enter your password</Heading3>
            {isError && (
              <Paraph className="text-red md:!text-2xl">
                Oops, wrong password!
              </Paraph>
            )}
            <input
              type="password"
              onChange={(e) => setPass(e.target.value)}
              className="rounded-none text-2xl sm:text-3xl md:text-4xl xl:text-5xl text-center border-[1.5px] border-black w-full h-12 md:h-24 bg-whitecream"
            />
            <button
              type="submit"
              className={`border-[1.5px] border-black w-full text-center h-12 md:h-24 hover:bg-yellow ${
                isLoading ? "bg-yellow" : ""
              }`}
            >
              {isLoading ? <PulseLoader /> : <Heading3>Go!</Heading3>}
            </button>
          </label>
        </form>
        <div className="text-center">
          <Paraph className="font-medium text-base md:!text-2xl">
            Don't have a password?
          </Paraph>
          <Paraph className="font-thin text-base md:!text-2xl">
            Sorry, due to client confidenciality I can't share more details in
            here, please get in touch if you want to know more.
          </Paraph>
        </div>
      </div>
    </div>
  );
}
