import { MutableRefObject } from "react";
import svg from "svg";
import Heading3 from "components/text/Heading3";
import Paraph from "components/text/Paraph";

const data = [
  "Helping define brand and product strategies",
  "Planning, facilitating and reporting UX research activities: usability testing, interviews and focus groups",
  "Working collaboratively with product owners, copy writers, designers, developers and media managers",
  "Identifying and prioritising design requirements",
  "Creating brand design systems and guidelines, including ad-hoc illustrations and animations",
];

interface Props {
  className: string;
  setRef: MutableRefObject<null>;
}
export default function Learned({ className, setRef }: Props) {
  return (
    <div
      className={`cols-start-1 col-span-12 sm:row-start-2 sm:col-start-3 sm:col-span-6 ${className}`}
    >
      <Heading3 ref={setRef} className="pb-8">
        What I’ve learned…
      </Heading3>
      <Paraph className="pb-4">
        Owning and managing designs throughout their lifecycle:
      </Paraph>
      {data.map((lesson, index) => (
        <div key={index} className="grid grid-cols-[2rem_1fr] items-start">
          <img
            className="w-5 md:w-6 pt-0.5 h-8 md:pt-3"
            src={svg.asterisk}
            alt="asterisk"
          />
          <Paraph className="pb-4 text-[4rem]">{lesson}</Paraph>
        </div>
      ))}
    </div>
  );
}
