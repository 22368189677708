import Paraph from "components/text/Paraph";
import { HashLink } from "react-router-hash-link";

interface Props {
  items: string[];
}

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export default function ContentsMenu({ items }: Props) {
  return (
    <div className="col-span-full flex flex-col gap-2 md:gap-10">
      <Paraph className="">Contents</Paraph>
      <div className="flex flex-col gap-2 md:gap-4">
        {items.map((item, i) => (
          <HashLink
            smooth
            className="uppercase underline hover:bg-underline hover:no-underline w-min whitespace-nowrap text-lg md:text-2xl font-light"
            key={`${item}${i}`}
            to={`#${item}`}
            scroll={scrollWithOffset}
          >
            {item}
          </HashLink>
        ))}
      </div>
    </div>
  );
}
